import React, { ComponentProps } from 'react'
import { Box, styled } from '@mui/material'
import { PropaneSharp } from '@mui/icons-material'

const InputLabel = styled('label')<{ isError?: boolean;}>`
  font-family: 'tt-talent', Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 3px;
  letter-spacing: 0.2px;
  color: ${props => props.isError ? '#FB4458' : '#828BA2'};
`

const Input = styled('input')<{ isError?: boolean;}>`
  font-size: 17px;
  font-family: 'tt-talent', Helvetica, Arial, sans-serif;
  color: rgb(51, 63, 87);
  height: 42px;
  border-radius: 6px;
  border: 1px solid transparent;
  background-color: rgb(246, 247, 250);
  text-indent: 5px;
  outline: none;
  border-bottom: ${props => props.isError && '2px solid #FB4458'};

  &:hover {
    border-color: #aebad3;
  };

  &:focus {
    outline: none;
  };
`

const HelperText = styled('p')<{ isError?: boolean;}>`
  background: ${props => props.isError ? 'transparent' : '#ff0'};
  color: ${props => props.isError ? '#FB4458' : '#202124'};
  font-family: ${props => props.isError && 'tt-talent'};
  padding: ${props => props.isError ? '0px' : '2.5px 5px'};
  width: fit-content;
  font-size: ${props => props.isError && '12px'};
  font-weight: ${props => props.isError ? 400 : 600};
  margin-top: ${props => props.isError ? '4px' : '6px'};
`

type Props = ComponentProps<'input'> & {
  label: string
  helperText?: string
  isError?: boolean
}

export const TextInput = (props: Props) => {
  const { label, helperText, isError, ...inputProps } = props

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <InputLabel isError={isError && helperText}>{label}</InputLabel>
      <Input isError={isError && helperText} {...inputProps} />
      {helperText ? <HelperText isError>{helperText}</HelperText> : null}
    </Box>
  )
}

TextInput.Wrap = styled(Box)({
  display: 'flex',
  gap: 15,
  flex: 1,
  flexWrap: 'nowrap',

  '@media (max-width: 425px)': {
    flexWrap: 'wrap',
  },
})
