import axios from 'axios'
import * as types from './types'
import { BrowserInfo } from '@adyen/adyen-web'

declare const VWO: any

const triggerVWOTracking = (
  isSuccess: boolean,
  amount: number = 0,
  packageName: string = ''
): void => {
  try {
    if (isSuccess) {
      VWO.event('purchase', {
        revenue: amount,
        checkout: true,
        package: packageName,
      })
    } else {
      VWO.event('purchase', {
        revenue: 0,
        checkout: false,
        package: packageName,
      })
    }
  } catch (error) {
    console.error('Error triggering VWO event:', error)
  }
}

export const getSession = () => {
  return axios.get<any>('/api/v2/session')
}

export const getUserById = (id: string) => {
  return axios.get<types.User>(`/api/v2/users/${id}`)
}

export const getOrder = (body: types.OrderPayload) => {
  return axios.get<types.OrderData>(
    `/api/v2/users/${body?.userId}/orders/${body?.orderId}`
  )
}

export const createAffirmCharge = (
  body: types.CreateAffirmChargePaylod,
  pt?: string
) => {
  return axios
    .post<types.OrderResponse>('/api/v2/payments/affirm/charge', body, {
      params: { pt },
    })
    .then((response) => {
      const orderResponse = { data: response.data }

      const { orderId, products } = orderResponse.data
      const amount = products?.totalPrice || 0
      const packageName = products?.planPrices[0]?.planName || 'Unknown Package'

      triggerVWOTracking(!!orderId, amount, packageName)

      return orderResponse
    })
    .catch((error) => {
      const packageName = body.planCodes?.[0] || 'Unknown Package'
      triggerVWOTracking(false, 0, packageName)
      throw error
    })
}

export const getAdyenCheckoutConfig = async (planCodes: string | Array<string>) => {
  return axios.get<any>(`/api/v2/payments/adyen/checkouts?plan_code=${planCodes}`)
}

export const getPurchaseStatus = (purchaseId: string) => {
  return axios.get<any>(`/api/v2/payments/purchases/${purchaseId}`)
}

type CreateAdyenPaymentRequestPayload = {
  discountCode?: string,
  planCodes: string | string[],
  recaptcha: {
    token: string;
    version: number;
  },
  paymentMethod: object,
  browserInfo?: BrowserInfo,
  riskData?: {clientData: string},
  billingAddress?: object,
  customer: {
    name: string,
    lastName: string,
    email: string,
    timezone?: string
  },
  giftee?: {
    firstName: string,
    lastName: string,
    email: string,
    note?: string
  }
}

export const createAdyenPayment = (payload: CreateAdyenPaymentRequestPayload) => {
  return axios.post<any>(
    '/api/v2/payments/adyen/purchases',
    payload
  )
}

type AdditionalDetailsRequestPayloadType = {
  details: {
    [key: string]: any;
    redirectResult?: string | undefined;
    threeDSResult?: string | undefined;
  },
  paymentData?: string | undefined;
  sessionData?: string | undefined;
}

export const submitAdditionalPaymentDetails = (payload: AdditionalDetailsRequestPayloadType) => {
  return axios.post<any>(
    '/api/v2/payments/adyen/additional_payment_details',
    payload
  )
}

export const createBraintreeClientToken = () => {
  return axios.post<types.BraintreeClientTokenResponse>(
    '/api/v2/payments/braintree/clienttoken'
  )
}

export const createBraintreeCharge = (
  body: types.CreateBraintreeChargePayload,
  pt?: string
) => {
  return axios
    .post<types.OrderResponse>('/api/v2/payments/braintree/charge', body, {
      params: { pt },
    })
    .then((response) => {
      const orderResponse = { data: response.data }

      const { orderId, products } = orderResponse.data
      const amount = products?.totalPrice || 0
      const packageName = products?.planPrices[0]?.planName || 'Unknown Package'

      triggerVWOTracking(!!orderId, amount, packageName)

      return orderResponse
    })
    .catch((error) => {
      const packageName = body.planCodes?.[0] || 'Unknown Package'
      triggerVWOTracking(false, 0, packageName)
      throw error
    })
}

export const calculateProductPrices = (body: types.CalculatePricesPayload) => {
  return axios.post<types.CalculatePricesResponse>(
    '/api/v2/payments/prices/calculate',
    body
  )
}
