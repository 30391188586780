import { AdyenCheckout, ApplePayButtonType, Dropin, GooglePayConfiguration, PaymentAmountExtended } from '@adyen/adyen-web/auto'
import '@adyen/adyen-web/styles/adyen.css'
import useGTM from '@elgorditosalsero/react-gtm-hook'
import { ChevronLeft } from '@mui/icons-material'
import { Box, Button, useTheme } from '@mui/material'
import ComplianceNote from '@talentinc/gatsby-theme-ecom/components/ComplianceNote/ComplianceNote'
import BlueDotLoader from '@talentinc/gatsby-theme-ecom/components/Loaders/BlueDotLoader'
import { tagPurchaseGTMEvent } from '@talentinc/gatsby-theme-ecom/components/NewUpsell/TagPurchaseEvent'
import ComplianceNoteProvider, {
  useComplianceNote,
} from '@talentinc/gatsby-theme-ecom/hooks/useComplianceNote'
import useSiteMetadata from '@talentinc/gatsby-theme-ecom/hooks/useSiteMetadata'
import { WidgetTRefs } from '@talentinc/gatsby-theme-ecom/types/widget'
import { isProduction } from '@talentinc/gatsby-theme-ecom/utils/env'
import { useFormik } from 'formik'
import { navigate } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { Dispatch, MutableRefObject, RefObject, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import useLocation from 'react-use/lib/useLocation'
import { makeStyles } from 'tss-react/mui'
import * as Yup from 'yup'
import useBrand from '../../hooks/useBrand'
import { TelemetryHandler, useBETelemetry } from '../../hooks/useTelemetry'
import * as logger from '../../utils/logging'
import { Checkbox } from './Checkbox'
import { TextInput } from './TextInput'
import './adyen.css'
import { createAdyenPayment, getAdyenCheckoutConfig, submitAdditionalPaymentDetails } from './api'
import { CalculatePricesResponse, OrderResponse } from './types'
import { camel2snake, getTimezone, isURLNew } from './utils'
import { toast } from 'react-hot-toast'

const Schema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  terms: Yup.bool().oneOf([true], 'Terms of Service must be approved'),
  email: Yup.string()
    .email('A valid Email Address is required')
    .required('Email Address is required'),
})

const SchemaForSomeoneElse = Schema.concat(
  Yup.object().shape({
    otherFirstName: Yup.string().required('First name is required'),
    otherLastName: Yup.string(),
    terms: Yup.bool().oneOf([true], 'Terms of Service must be approved'),
    otherEmail: Yup.string()
      .email('A valid Email Address is required')
      .required('Email Address is required'),
    otherNote: Yup.string(),
  })
)

type CaptchaFallbackType = {
  satisfied: boolean,
  token: string
}

type Props = {
  discountToken: string
  planCodes: string[]
  telemetry?: TelemetryHandler
  pricesProducts?: CalculatePricesResponse
  isLoading: boolean
  user?: any
  captchaFallback: CaptchaFallbackType
  setCaptchaFallback: Dispatch<SetStateAction<CaptchaFallbackType>>
  captchaV2Ref: MutableRefObject<string | null>
}

const CaptchaV2Fallback = ({
  display,
  onChange,
}: {
  display: boolean
  onChange: (token: string | null) => void
}) => {
  if (!display) return null

  return (
    <ReCAPTCHA
      sitekey="6LddkAMqAAAAACW8YcxOEVY0_gK3cD7NiTk3vgqe"
      onChange={onChange}
    />
  )
}

export const CheckoutForm = (props: Props) => {
  const { pathname, hostname } = useLocation()
  const { flagshipProduct, name: brandName } = useBrand()
  const { planCodes, discountToken, pricesProducts, isLoading, user, captchaFallback, setCaptchaFallback, captchaV2Ref} = props
  const adyenCheckoutRef = useRef<string>('adyenCheckoutRef')
  const purchaseId = useRef<string>()

  const siteMetadata = useSiteMetadata()

  const { sendDataToGTM } = useGTM()
  const telemetry = useBETelemetry()

  const [isForSomeoneElse, setIsForSomeoneElse] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [shouldMountDropin, setShouldMountDropin] = useState(true)

  const ComplianceNoticeSingle = () => {
    const { t } = useTranslation()
    const { handleComplianceModalOpen } = useComplianceNote()

    return (
      <ComplianceNote>
        <ComplianceNote.TextLink
          style={{
            textTransform: 'uppercase',
            letterSpacing: '2px',
            fontSize: 12,
            textDecoration: 'none',
          }}
          onClick={(e: any) => {
            e.stopPropagation()
            handleComplianceModalOpen(e)
          }}
        >
          {t(`${WidgetTRefs.CommonTRef}.tos`)}
        </ComplianceNote.TextLink>
      </ComplianceNote>
    )
  }

  const style = makeStyles()((theme) => ({
    purchaseForm: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'end',
      marginTop: '20px',

      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        flexDirection: 'column-reverse',
      },
    },
  }))

  const theme = useTheme()
  const { classes } = style()


  const getCaptchaV3 = useCallback(async () => {
    try {
      if (captchaV2Ref.current)
        return {
          token: captchaV2Ref.current,
          version: 2,
        }

      const captcha = await executeRecaptcha?.('adyenCheckout')
      if (!captcha) throw new Error('Captcha not satisfied')

      return { token: captcha, version: 3 }
    } catch (e) {
      logger.error(e)
      return null
    }
  }, [executeRecaptcha])

  useEffect(() => {
    getCaptchaV3();
  }, [getCaptchaV3]);

  const handleCaptchaV3Denied = () => {
    captchaV2Ref.current = null
    setCaptchaFallback({
      satisfied: false,
      token: '',
    })
  }

  const triggerGTMEvent = (payload: OrderResponse) => {
    try {
      if (isProduction) {
        const { gtmData, isNewTransaction } = tagPurchaseGTMEvent(payload, {
          pathname,
          hostname,
        })

        isNewTransaction && sendDataToGTM(gtmData)
      }
    } catch (e) {
      logger.error(e)
      return null
    }
  }

  const getPartnerToken = useCallback(() => {
    const query = new URLSearchParams(window.location.search)

    return query.get('pt') ?? ''
  }, [])

  const getRedirectURL = useCallback(() => {
    const query = new URLSearchParams(window.location.search)

    return query.get('redirect_url') ?? ''
  }, [])

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      otherFirstName: '',
      otherLastName: '',
      otherEmail: '',
      otherNote: '',
      terms: true
    },
    validationSchema: isForSomeoneElse ? SchemaForSomeoneElse : Schema,
    onSubmit: async (values) => {
      // Workaround to get the values on Adyen's onSubmit. Accessing formik directly does not update the values
      return values
      // try {
      //   setLoadingSubmit(true)

      //   const paymentMethod = await requestPaymentMethod()
      //   if (!paymentMethod) {
      //     setLoadingSubmit(false)
      //     return
      //   }

      //   const timezone = getTimezone()

      //   const recaptcha = await getCaptchaV3()
      //   if (!recaptcha) {
      //     setLoadingSubmit(false)
      //     return
      //   }

      //   const pt = getPartnerToken()

      //   const charge = await createBraintreeCharge(
      //     {
      //       recaptcha,
      //       nonce: paymentMethod.nonce,
      //       deviceData: paymentMethod.deviceData,
      //       planCodes,
      //       discountToken,
      //       customer: {
      //         firstName: values.firstName,
      //         lastName: values.lastName,
      //         email: values.email,
      //         ...(timezone && { timezone }),
      //       },
      //       ...(isForSomeoneElse && {
      //         giftee: {
      //           firstName: formik.values.otherFirstName,
      //           lastName: formik.values.otherLastName,
      //           email: formik.values.otherEmail,
      //           note: formik.values.otherNote,
      //         },
      //       }),
      //     },
      //     pt
      //   )

      //   // TODO: Remove this after the BE are able to restore giftee data
      //   if (isForSomeoneElse) {
      //     localStorage.setItem(
      //       'giftee' + charge.data.orderId,
      //       JSON.stringify({
      //         firstName: formik.values.otherFirstName,
      //         lastName: formik.values.otherLastName,
      //         email: formik.values.otherEmail,
      //         note: formik.values.otherNote,
      //       })
      //     )
      //   }

      //   triggerGTMEvent(charge.data)

      //   setTimeout(() => {
      //     const redirect_url = getRedirectURL()
      //     setLoadingSubmit(false)

      //     if (redirect_url) {
      //       const redirectURL = redirect_url + '?bought=1'
      //       window?.location?.replace(redirectURL)
      //     } else {
      //       const isNew = isURLNew(pathname as string)
      //       navigate(`${isNew ? '/new/' : '/'}upsell?order=${charge.data.orderId}`)
      //     }
      //   }, 1000)
      // } catch (e) {
      //   const status = (e as AxiosError).response?.status
      //   const errorCode = (e as AxiosError<{ errorCode: string }>).response?.data
      //     ?.errorCode

      //   if (errorCode === 'INVALID_CAPTCHA') {
      //     setLoadingSubmit(false)
      //     handleCaptchaV3Denied()
      //     toast.error('Failed to validate captcha - please try again.')
      //   } else {
      //     if (status && status >= 500 && status <= 599) {
      //       toast.error('Payment method was not accepted')
      //     } else {
      //       toast.error('Unable to complete the purchase')
      //     }
      //   }

      //   logger.error(e)
      //   setLoadingSubmit(false)
      // }
    },
  })

  const showErrorToast = () => {
    toast.error('There was an error with you purchase. Please try again or contact support')
  }

  const handleServerResponse = (response: any, component: any, formValues?: any) => {
    const {host, protocol} = window.location

    if(!response) {
      showErrorToast()
      return
    }

    if (response.payment_response?.action) {
      setLoadingSubmit(false)
      component.handleAction(response.payment_response.action)
    } else {
      if(!response.status || !response.id || !formValues || !planCodes) {
        showErrorToast()
        return
      }

      let redirectUrl = getRedirectURL()

      if (redirectUrl) {
        redirectUrl = redirectUrl + '?bought=1'
      } else {
        redirectUrl = `${isURLNew(pathname as string) ? '/new/' : '/'}upsell?order=${response.id}`
      }

      switch (response.status.toLowerCase()) {
        case 'authorised':
        case 'pending':
        case 'received':
          //this.parent.toggleLoading(true)
          window.location.href = `${protocol}//${host}/${
            'purchase/processing?' + 'purchaseId=' + response.id + '&redirectUrl=' + redirectUrl + '&plan=' + planCodes + '&name=' + formValues.firstName
          }`
          break
        default:
          // trackInternalEvent('payment_error', {
          //   type: 'authorization',
          //   error: JSON.stringify(response),
          // })
          window.location.href = `${protocol}//${host}/${
            'purchase/failed?' + 'purchaseId=' + response.id + '&plan=' + planCodes + '&name=' + formik.values.firstName
          }`
      }
    }
  }

  const initDropin = useCallback(async () => {
    const adyenCheckoutConfigRaw = await getAdyenCheckoutConfig(planCodes)
    const adyenCheckoutConfig = adyenCheckoutConfigRaw.data

    //TODO: fix constructor type
    const adyenCheckout = await AdyenCheckout({
        clientKey: adyenCheckoutConfig.gatewayKey,
        environment: adyenCheckoutConfig.gatewayEnv as "test" | "live" | "live-us" | "live-au" | "live-apse" | "live-in" | undefined,
        locale: siteMetadata.locale,
        amount: adyenCheckoutConfig.amount as PaymentAmountExtended | undefined, //pricesProducts?.totalPrice as PaymentAmountExtended | undefined,
        countryCode: adyenCheckoutConfig.countryCode,
        translations: {
          'en-US': {
            'payButton.saveDetails': 'Pay',
          }
        },
        paymentMethodsResponse: {paymentMethods: adyenCheckoutConfig.paymentMethodsResponse},
        onSubmit: async (state, dropin, actions) => {
          setLoadingSubmit(true)
          // Run Formik validation on all fields before proceeding
          const errors = await formik.validateForm()
          // Collecting form data and error messages
          const errorTextArray = []

          // Collect Formik validation errors
          if (errors.firstName) errorTextArray.push('First name is required')
          if (errors.lastName) errorTextArray.push('Last name is required')
          if (errors.email) errorTextArray.push('Email Address is required')
          if (errors.terms) errorTextArray.push('Approve the terms of service')

          // If "Order for Someone Else" is checked, validate those fields too
          if (isForSomeoneElse) {
            if (errors.otherFirstName)
              errorTextArray.push("Other person's First name is required")
            if (errors.otherEmail)
              errorTextArray.push("Other person's Email Address is required")
          }

          // Additional properties for telemetry with flexible typing
          const additionalProperties: Record<string, any> = {
            entered_email: formik.values.email || '',
            entered_first_name: formik.values.firstName || '',
            entered_last_name: formik.values.lastName || '',
          }

          // If there are errors, serialize the errorTextArray and add error_text to additionalProperties
          if (errorTextArray.length > 0) {
            const errorText = JSON.stringify(errorTextArray)
            additionalProperties.error_text = errorText // Add error_text dynamically
          }

          // Tracking complete order click with additional properties
          telemetry.track({
            event: 'click_complete_order',
            properties: {
              ...additionalProperties, // Merging additional properties
            },
          })

          // Workaround to get the values on Adyen's onSubmit.
          // Accessing formik directly does not have the updated values as useFormik is not intended to be used this way
          const formValues = await formik.submitForm()

          const recaptcha = await getCaptchaV3()

          if(errorTextArray.length === 0 && recaptcha?.token) {
            const timezone = getTimezone()

            const createPaymentPayload = {
              ...state.data,
              recaptcha,
              discountCode: discountToken,
              planCodes: planCodes,
              customer: {
                name: formValues.firstName,
                lastName: formValues.lastName,
                email: formValues.email,
                ...(timezone && { timezone }),
              },
              ...(isForSomeoneElse && {
                giftee: {
                  firstName: formValues.otherFirstName,
                  lastName: formValues.otherLastName,
                  email: formValues.otherEmail,
                  note: formValues.otherNote,
                },
              }),
            }

            // trackInternalEvent('pay_button', { plan: urlParams.plan })

            createAdyenPayment(createPaymentPayload)
              .then(response => {
                if(!response?.data?.id){
                  // TODO: refactor these repeated error recovery lines into a fn
                  actions.reject()
                  dropin.setStatus('ready')
                  setLoadingSubmit(false)
                }
                purchaseId.current = response.data.id
                handleServerResponse(response?.data, dropin, formValues)
              })
              .catch(error => {
                console.error('error response', error)
                setLoadingSubmit(false)
                if (error?.response?.data?.error === 'INVALID_CAPTCHA') {
                  handleCaptchaV3Denied()
                } else {
                  showErrorToast()
                }
              })
          } else {
            actions.reject()
            dropin.setStatus('ready')
            if(!recaptcha) {
              handleCaptchaV3Denied()
            }
            setLoadingSubmit(false)
          }
        },
        onAdditionalDetails: (state, component, actions) => {
          if(!purchaseId.current) {
            actions.reject()
            showErrorToast()
          } else {
            submitAdditionalPaymentDetails(state.data, purchaseId.current)
              .then(response => {
                handleServerResponse(response, component)
              })
              .catch((error) => {
                console.error(error)
                showErrorToast()
                actions.reject()
              })
          }
        },
        onError: (error, component) => {
          // trackInternalEvent('payment_error', {
          //   type: 'authorization',
          //   error: `${error.name}--${error.message}`,
          // })
          console.error(error.name, error.message, error.stack, component)
        },
    })

    const dropinConfiguration = {
      showRadioButton: true,
      showStoredPaymentMethods: false,
      disableFinalAnimation: true,
      openFirstPaymentMethod: true,
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          placeholders: {
            cardNumber: '1234 5678 9012 3456',
            expiryDate: 'MM/YY',
            securityCodeThreeDigits: '123',
            holderName: 'John Smith',
          },
          billingAddressRequired: false, //TODO: Do the math
          data: {
            billingAddress: {
              country: adyenCheckoutConfig.countryCode,
            },
          },
        },
        paywithgoogle: {
          buttonType: "plain" as GooglePayConfiguration["buttonType"],
        },
        applepay: {
          buttonType: "plain" as ApplePayButtonType,
          buttonColor: "black" as "black" | "white" | "white-with-line" | undefined,
        },
        paypal: {
          style: {
            height: 48,
          },
        },
      },
    }

    if(shouldMountDropin){
      setShouldMountDropin(false)
      new Dropin(adyenCheckout, dropinConfiguration).mount(adyenCheckoutRef.current)
    }
  }, [adyenCheckoutRef, getCaptchaV3, handleCaptchaV3Denied, purchaseId, shouldMountDropin])

  useEffect(() => {
    if(adyenCheckoutRef.current){
      initDropin()
    }

  }, [])

  // useEffect(() => {
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pricesProducts?.totalPrice])

  // const onSubmitAffirm = useCallback(async () => {
  //   telemetry.track({
  //     event: 'affirm_radio_button_click',
  //     properties: { event_type: 'checkout_event' },
  //   })

  //   // has validation errors
  //   if (Object.keys(await formik.validateForm()).length > 0) {
  //     return
  //   }

  //   try {
  //     setLoadingSubmit(true)

  //     affirmCheckout({
  //       user: {
  //         name: `${formik.values.firstName} ${formik.values.lastName}`,
  //         email: formik.values.email,
  //       },
  //       items:
  //         pricesProducts?.planPrices?.map((i) => ({
  //           sku: i.planCode,
  //           unitPrice: i.price,
  //           displayName: i.planName,
  //         })) ?? [],
  //       currency: pricesProducts?.planPrices?.at(0)?.currencyCode || 'USD',
  //       total: (pricesProducts?.totalPrice ?? 0) * 100,
  //     })

  //     affirmOpen({
  //       onSuccess: async (args) => {
  //         try {
  //           const timezone = getTimezone()

  //           const pt = getPartnerToken()

  //           const charge = await createAffirmCharge(
  //             {
  //               planCodes,
  //               discountToken,
  //               checkoutToken: args.checkout_token,
  //               customer: {
  //                 email: formik.values.email,
  //                 firstName: formik.values.firstName,
  //                 lastName: formik.values.lastName,
  //                 ...(timezone && { timezone }),
  //               },
  //               ...(isForSomeoneElse && {
  //                 giftee: {
  //                   firstName: formik.values.otherFirstName,
  //                   lastName: formik.values.otherLastName,
  //                   email: formik.values.otherEmail,
  //                   note: formik.values.otherNote,
  //                 },
  //               }),
  //             },
  //             pt
  //           ).catch((e) => {
  //             if (e?.response?.data?.error === 'INVALID_CAPTCHA') {
  //               setLoadingSubmit(false)
  //               handleCaptchaV3Denied()
  //             }
  //             throw e
  //           })

  //           // TODO: Remove this after the BE are able to restore giftee data
  //           if (isForSomeoneElse) {
  //             localStorage.setItem(
  //               'giftee' + charge.data.orderId,
  //               JSON.stringify({
  //                 firstName: formik.values.otherFirstName,
  //                 lastName: formik.values.otherLastName,
  //                 email: formik.values.otherEmail,
  //                 note: formik.values.otherNote,
  //               })
  //             )
  //           }

  //           const redirect_url = getRedirectURL()

  //           if (redirect_url) {
  //             const redirectURL = redirect_url + '?bought=1'
  //             window?.location?.replace(redirectURL)
  //           } else {
  //             const isNew = isURLNew(pathname as string)
  //             navigate(
  //               `${isNew ? '/new/' : '/'}upsell?order=${
  //                 charge.data.orderId
  //               }&affirm=1`
  //             )
  //           }
  //         } catch (e) {
  //           toast.error('Unable to complete the purchase')
  //           logger.error(e)
  //         } finally {
  //           setLoadingSubmit(false)
  //         }
  //       },
  //       onFail: (...e) => {
  //         toast.error('Affirm checkout not completed')
  //         logger.error(...e)
  //         setLoadingSubmit(false)
  //       },
  //     })
  //   } catch (e) {
  //     logger.error(e)
  //     setLoadingSubmit(false)
  //   }
  // }, [
  //   getRedirectURL,
  //   pricesProducts,
  //   formik,
  //   telemetry,
  //   discountToken,
  //   planCodes,
  //   getPartnerToken,
  //   isForSomeoneElse,
  //   pathname,
  // ])

  // [Telemetry]: Handle form blur events
  const onFieldBlur: React.FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      formik.handleBlur(e)

      if (!formik.getFieldMeta(e.target.name).error) {
        telemetry.track({
          event: `${camel2snake(e.target.name)}_added_to_form`,
          properties: { event_type: 'checkout_event' },
        })
      }
    },
    [formik, telemetry]
  )

  const onClickGoBack= () => {
    telemetry.track({
      event: 'back_to_services_click',
      properties: { event_type: 'checkout_event' },
    })

    navigate(`/${flagshipProduct}-writing`)
  }

  // [Telemetry]: Handle Braintree (dropin) events
  useEffect(() => {
    const onBlur = ({ emittedBy, fields }: any) => {
      if (fields[emittedBy].isValid) {
        telemetry.track({
          event: `${camel2snake(emittedBy)}_added_to_form`,
          properties: { event_type: 'checkout_event' },
        })
      }
    }

    const onSelect = ({ paymentOption }: any) => {
      telemetry.track({
        event: 'click_payment_method',
        properties: {
          label: paymentOption, // Capture the selected payment method
        },
      })
    }

    return () => {
    }
  }, [telemetry])

  useEffect(() => {
    if (user) {
      formik.setFieldValue('firstName', user.first_name, false)
      formik.setFieldValue('lastName', user.last_name, false)
      formik.setFieldValue('email', user.email, false)
    }
    // eslint-disable-next-line
  }, [user])

  const checkRequiredFieldsForSomeoneElse = useCallback(() => {
    if (formik.values.otherFirstName && formik.values.otherEmail) {
      telemetry.track({
        event: 'fill_order_for_someone_else_required_field',
        properties: {
          entered_name: formik.values.otherFirstName,
          entered_email: formik.values.otherEmail,
          plan_code: planCodes[0], // Assuming the first plan code represents the purchase
        },
      })
    }
  }, [formik.values.otherFirstName, formik.values.otherEmail, telemetry, planCodes])

  return (
    <Box position="relative">
      {(isLoading || loadingSubmit) && <BlueDotLoader variant="padded" />}
      <Box
        px={2}
        py={2}
        style={{
          borderRadius: '6px',
          background: 'white',
          padding: '16px 20px',
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box display="flex" flexDirection="column">
            <Box
              style={{
                display: 'flex',
                gap: 15,
                marginBottom: 20,
                flexWrap: 'wrap',
              }}
            >
              <TextInput.Wrap>
                <TextInput
                  label="First Name"
                  name="firstName"
                  onChange={formik.handleChange}
                  onBlur={onFieldBlur}
                  value={formik.values.firstName}
                  helperText={formik.errors.firstName}
                  isError
                />
                <TextInput
                  label="Last Name"
                  name="lastName"
                  onChange={formik.handleChange}
                  onBlur={onFieldBlur}
                  value={formik.values.lastName}
                  helperText={formik.errors.lastName}
                  isError
                />
              </TextInput.Wrap>
              <TextInput
                label="Email Address"
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={onFieldBlur}
                value={formik.values.email}
                helperText={formik.errors.email}
                isError
              />
            </Box>

            <Box mb={3}>
              <Checkbox
                id="isForSomeoneElse"
                value={isForSomeoneElse}
                onChange={() => {
                  setIsForSomeoneElse((i) => !i)

                  // Track the "Order for someone else" checkbox click
                  telemetry.track({
                    event: 'click_order_for_someone_else',
                    properties: {
                      plan_code: planCodes[0], // Assuming the first plan code represents the purchase
                    },
                  })
                }}
                label="Is this order for someone else?"
              />
            </Box>

            {isForSomeoneElse ? (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 15,
                  marginBottom: 20,
                  marginTop: 10,
                }}
              >
                <TextInput.Wrap>
                  <TextInput
                    label="First name"
                    name="otherFirstName"
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e)
                      checkRequiredFieldsForSomeoneElse()
                    }}
                    value={formik.values.otherFirstName}
                    helperText={formik.errors.otherFirstName}
                  />
                  <TextInput
                    label="Last name"
                    name="otherLastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.otherLastName}
                    helperText={formik.errors.otherLastName}
                  />
                </TextInput.Wrap>
                <TextInput.Wrap>
                  <TextInput
                    label="Email address"
                    name="otherEmail"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={(e) => {
                      formik.handleBlur(e)
                      checkRequiredFieldsForSomeoneElse()
                    }}
                    value={formik.values.otherEmail}
                    helperText={formik.errors.otherEmail}
                  />
                  <TextInput
                    label="Note to recipient"
                    name="otherNote"
                    onChange={formik.handleChange}
                    value={formik.values.otherNote}
                    helperText={formik.errors.otherNote}
                  />
                </TextInput.Wrap>
              </Box>
            ) : null}

            <Box ref={adyenCheckoutRef} id="adyen-dropin-container" />

          </Box>
        </form>
      </Box>
      <Box mt={2}>
        <ComplianceNoteProvider>
          <Box>
            <Box
              display="flex"
              flexDirection="column"
              gap="1px"
              alignItems="flex-start"
              justifyItems="center"
            >
              <Box
                display="flex"
                flexDirection="row"
                gap="1px"
                alignItems="center"
                justifyItems="center"
              >
                <Checkbox
                  onChange={formik.handleChange}
                  value={formik.values.terms}
                  id="terms"
                  label={<>by placing your order, you agree to {brandName}'s </>}
                />
                <Box ml="3px">
                  <ComplianceNoticeSingle />.
                </Box>
              </Box>
              {formik.errors.terms && <span style={{color: "red", fontSize: '12px', marginLeft: "28px"}}>
                {formik.errors.terms}
              </span>}
            </Box>

            <Box className={classes.purchaseForm}>
              <Button onClick={onClickGoBack} variant="text" style={{ padding: 0 }}>
                <Box
                  style={{
                    color: 'rgb(51, 63, 87)',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gridGap: 3,
                  }}
                  sx={{
                    [theme.breakpoints.down('sm')]: {
                      marginTop: '30px',
                    },
                  }}
                >
                  <ChevronLeft
                    fontSize="small"
                    style={{
                      position: 'relative',
                      top: -1,
                    }}
                  />
                  <span
                    style={{
                      fontSize: 17,
                      lineHeight: 1,
                    }}
                  >
                    Back To Services
                  </span>
                </Box>
              </Button>
            </Box>
          </Box>
        </ComplianceNoteProvider>
      </Box>
    </Box>
  )
}
